@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Calistoga:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@100;200;300;400;500;600;700;800;900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  margin: 0;
}

body {
  overflow: initial !important;
}

body {
  font-family: 'Work Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #ececec;
}

.message-html ul {
  list-style: disc !important;
  padding-left: 2rem !important;
}

.message-html ol {
  list-style: decimal !important;
  padding-left: 2rem !important;
}

input,
select,
textarea,
button {
  -moz-box-shadow: none;
  outline: none !important;
}

/* 
  FullCalendar Overriding Properties
  https://fullcalendar.io/docs/css-customization
 */
:root {
  --fc-button-text-color: #223bf4;
  --fc-button-bg-color: #eef0fe;
  --fc-button-border-color: transparent;
  --fc-button-hover-bg-color: #eef0fe;
  --fc-button-hover-border-color: transparent;
  --fc-button-active-bg-color: #eef0fe;
  --fc-button-active-border-color: transparent;
  --fc-highlight-color: rgba(34, 59, 244, 1);
  --fc-today-bg-color: rgba(238, 240, 254, 0.7);
}

.fc .fc-header-toolbar {
  padding: 0 28px;
}

.fc .fc-daygrid-day-frame {
  min-height: 150px;
}

.fc .fc-toolbar-title {
  font-size: 24px;
  font-family: 'Work Sans', sans-serif;
  font-weight: 700;
  white-space: nowrap;
}

.fc-header-toolbar .fc-button {
  padding: 4px;
  font-size: 15px;
  font-family: 'Work Sans', sans-serif;
  font-weight: 500;
  border-radius: 4px !important;
  text-transform: capitalize;
  box-shadow: none !important;
  outline: none !important;
}

.fc-header-toolbar .fc-toolbar-chunk:first-child,
.fc-header-toolbar .fc-toolbar-chunk:last-child {
  min-width: 126px;
}

.fc-dayGridMonth-button:not(.fc-button-active),
.fc-listWeek-button:not(.fc-button-active) {
  color: #4a4a68;
  background-color: white;
}

.fc-prev-button,
.fc-next-button,
.fc-prev-button:active,
.fc-next-button:active {
  background-color: white !important;
}

.fc-prev-button:hover,
.fc-next-button:hover {
  background-color: #eef0fe !important;
}

.fc-today-button,
.fc-next-button {
  margin-left: 4px !important;
}

@media (max-width: 768px) {
  .fc .fc-toolbar-title {
    font-size: 14px;
  }

  .fc .fc-header-toolbar {
    padding: 0 12px !important;
  }

  .fc-header-toolbar .fc-toolbar-chunk:first-child {
    min-width: 110px;
  }
}

@layer components {
  .auth-container {
    @apply px-2 sm:px-4 max-w-[1430px] mx-auto w-full;
  }

  .main-container {
    @apply flex flex-col px-4 lg:px-8;
  }

  .guest-wrapper {
    @apply max-w-[1192px];
  }

  .grey-container {
    @apply flex flex-col p-6 font-medium bg-grey-50 rounded-2xl text-regular text-grey-700;
  }

  .help-text {
    @apply text-gray-600;
  }

  .text-1 {
    @apply text-base text-white-two;
  }

  .text-heading {
    @apply text-xl xl:text-2xl text-white-one;
  }

  .text-heading-dark {
    @apply text-lg font-bold text-core-dark whitespace-nowrap;
  }

  .text-main {
    @apply font-medium text-regular text-core-dark;
  }

  .base-button {
    @apply w-full text-base cursor-pointer text-heading rounded-2xl disabled:bg-gray-regular disabled:text-gray-500;
  }

  .table-header {
    @apply text-xs font-normal text-center uppercase text-core-dark;
  }

  .button-primary {
    @apply w-auto px-6 py-3 text-sm font-semibold bg-blue-regular hover:bg-blue-700 hover:shadow whitespace-nowrap;
  }

  .button-primary:disabled {
    @apply text-blue-500 bg-blue-100 pointer-events-none;
  }

  .button-outline {
    @apply w-auto px-6 py-3 text-sm font-semibold transition-shadow bg-white border text-blue-primary whitespace-nowrap border-blue-primary hover:shadow-light;
  }

  .button-outline:disabled {
    @apply text-blue-500 bg-white border-blue-500 pointer-events-none;
  }

  .header-input {
    @apply block w-56 h-8 p-4 text-sm font-medium border text-grey-500 placeholder:text-sm placeholder:font-medium placeholder:text-grey-500 border-grey-200 ps-10 rounded-2xl focus:bg-gray-50 focus:border-blue-primary;
  }

  .class-row {
    @apply relative flex flex-col justify-between gap-2 px-4 py-4 transition-colors lg:px-8 xl:flex-row hover:bg-grey-50;
  }

  .class-first-column {
    @apply xl:min-w-[26rem] 3xl:min-w-[30rem];
  }

  .class-column-group {
    @apply flex flex-col items-start w-full gap-2 2xl:flex-row xl:flex-wrap 2xl:flex-nowrap xl:items-center xl:justify-center 2xl:justify-evenly;
  }

  .class-second-column {
    @apply 2xl:min-w-[8rem];
  }

  .class-third-column {
    @apply 2xl:w-full 2xl:max-w-[14rem];
  }

  .class-fourth-column {
    @apply 2xl:min-w-[15rem] 2xl:pr-10;
  }

  .nav-icon-label {
    @apply absolute bottom-1 lg:bottom-auto lg:mt-10 text-[8px] font-medium leading-3 capitalize select-none whitespace-nowrap;
  }

  .form-container {
    @apply max-w-[500px] px-4 sm:px-8 py-14 m-auto 2xl:m-0 bg-white rounded-2xl shadow-2xl text-grey-700;
  }

  .form-title {
    @apply grid w-full mb-8 text-4xl font-bold leading-none tracking-tight text-center justify-items-center text-core-dark;
  }

  .form-illustration-left {
    @apply absolute w-[177px] xl:w-auto mt-[-8rem] xl:mt-[-12.5rem] -ml-6;
  }

  .form-illustration-right {
    @apply absolute w-[177px] mb-[-1rem] sm:-mb-4 sm:mt-[-9.5rem] -mr-4 sm:-mr-8;
  }

  .banner-container {
    @apply mb-8 p-4 flex flex-col gap-2 max-w-[26rem] text-xs xl:text-regular text-core-dark bg-blue-100 rounded-lg border-2 border-white;
  }

  .message-container {
    @apply flex flex-col w-full gap-2 p-4 mb-6 text-center bg-red-100 rounded-lg text-core-dark;
  }

  .action-link {
    @apply font-bold text-blue-one;
  }

  .title-calistoga {
    @apply text-lg sm:text-[21px] calistoga;
  }

  .course-group-container {
    @apply flex flex-wrap justify-center gap-6 pt-10 md:justify-start;
  }

  .card {
    @apply w-full min-w-[16rem] flex flex-col rounded-2xl;
  }

  .course-card {
    @apply card relative sm:min-w-[17.5rem] md:w-[calc(50%-0.85rem)] xl:w-[calc(33%-0.85rem)] max-w-[24rem] bg-white hover:shadow-light transition-shadow;
  }

  .private-course-card {
    @apply course-card 2xl:w-[calc(33%-0.85rem)] 3xl:w-[calc(25%-1.2rem)];
  }

  .review-card {
    @apply card md:w-[calc(50%-0.5rem)] xl:w-[calc(33%-0.5rem)] gap-4 justify-between bg-grey-50;
  }

  .inner-html {
    @apply text-lg text-core-dark [&>p]:!mb-4 [&>ul]:!list-disc [&>ol]:!list-decimal [&_li]:!pl-4 [&_li]:!leading-7;
  }

  .dropdown-item {
    @apply flex items-center p-1 rounded cursor-pointer hover:bg-grey-100;
  }

  .settings-table {
    @apply text-regular font-medium text-grey-700 [&_tbody]:space-y-2 [&_tr_td:first-child]:text-grey-500;
  }

  .settings-table tr {
    @apply flex max-md:flex-col;
  }

  .settings-table td {
    @apply p-1 align-top min-w-[10rem];
  }

  .underline-link {
    @apply text-sm font-normal underline cursor-pointer text-blue-primary;
  }

  .link {
    @apply cursor-pointer text-blue-primary hover:underline;
  }

  .settings-header {
    @apply text-4xl font-bold leading-relaxed text-black;
  }

  .side-info-card {
    @apply flex flex-col gap-4 p-6 font-medium bg-grey-50 rounded-2xl text-regular text-grey-700;
  }

  .mobile-order-card {
    @apply pt-0 fixed shadow-upper bottom-8 lg:-bottom-4 w-[calc(100%-2rem)] left-0 mx-4;
  }

  .checkout-tag {
    @apply text-sm font-semibold lato;
  }

  .price {
    @apply flex flex-col font-bold text-right text-regular;
  }

  .avatar {
    @apply w-8 h-8 border-2 rounded-full border-core-dark;
  }

  .checkbox {
    @apply grid w-6 h-6 border-2 rounded cursor-pointer border-grey-500 checked:border-core-dark place-content-center;
  }

  .stripe-input {
    @apply rounded-xl focus:!outline focus:outline-2 focus:!outline-blue-200 placeholder:text-neutral-500 sans text-base font-normal;
  }

  .stripe-label {
    @apply mb-0 text-core-dark sans text-regular;
  }

  .tooltip {
    @apply !p-1 !text-xs !text-grey-700 !bg-white !rounded shadow-light text-center;
  }

  .course-section {
    @apply w-full px-4 pt-6 pb-12 mx-auto xl:px-36;
  }

  .checkout-header {
    @apply text-2xl leading-relaxed sm:text-4xl calistoga text-core-dark;
  }
}

.inner-html ul,
.inner-html ol {
  padding-left: 2rem !important;
  padding-bottom: 1rem !important;
}

.sans {
  font-family: ui-sans-serif, system-ui, sans-serif;
}

.code {
  font-family: 'Work Sans', sans-serif;
}

.calistoga {
  font-family: 'Calistoga', sans-serif;
}

.lato {
  font-family: 'Lato', sans-serif;
}

input[type='number'] {
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
}

input[type='checkbox'] {
  -webkit-appearance: none;
  appearance: none;
  background-color: transparent;
  margin: 0;
  display: grid;
  place-content: center;
}

input[type='checkbox']::before {
  content: '';
  width: 1.7em;
  height: 1.7em;
  background-size: contain;
}

input[type='checkbox']:checked::before {
  background-image: url('assets/images/checkmark.svg');
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

@media (min-width: 768px) and (max-width: 950px) {
  .course-card:not(.guest-view .course-card),
  .review-card {
    width: 100%;
  }
}

@media (min-width: 1024px) and (max-width: 1166px) {
  .course-card,
  .review-card {
    width: 100%;
  }

  .private-course-card,
  .guest-view .course-card {
    width: calc(50% - 0.85rem);
  }
}

@media (min-width: 1167px) and (max-width: 1470px) {
  .course-card:not(.guest-view .course-card),
  .review-card {
    width: calc(50% - 0.85rem);
  }
}
